import React from 'react';

function App(props) {
 return (
    <div style={{ position: 'relative', height: '100%', background: '#333' }}>
      {
        props.src ?
        <iframe title="xPerience Video" width="100%" height="100%" src={props.src} frameBorder="0" scrolling="no" allowFullScreen="false"></iframe>
        : null
      }
    </div>
  );
}

export default App;