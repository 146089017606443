import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const element = document.querySelector('[react-clip="livestream"]')

if (element) {
  ReactDOM.render(
    <React.StrictMode>
      <App src={ element ? element.getAttribute('data-src') : null } />
    </React.StrictMode>,
    element
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
